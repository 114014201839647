const nextRoutes = require('next-routes');

module.exports = nextRoutes()
  .add('home', '/', 'HomePage')
  .add('candidate:resume', '/resume/:resumeId', 'ResumePage')
  .add('vacancies:create:tariff', '/vacancies/create', 'vacancies/create')
  .add('vacancies:create:form', '/vacancies/create/:tariffId', 'CreateOrUpdateVacancyPage')
  .add('vacancies:edit', '/vacancies/:vacancyId/edit', 'CreateOrUpdateVacancyPage')
  .add('vacancies:list', '/vacancies', 'v2/CompanyVacanciesPage')
  .add('vacancies:candidates', '/vacancies/:vacancyId', 'VacancyPage')
  .add('vacancies:candidates-v2', '/vacancies/:vacancyId/candidates', 'v2/CompanyVacancyPage')
  .add('my:company:edit', '/my/company/edit', 'Company/CompanyEdit/EditCompanyPage')
  .add('my:company:teams', '/my/company/teams', 'Company/CompanyEdit/EditTeamsPage')
  .add('my:company:billing', '/my/company/billing', 'Company/BillingPage/BillingPage')
  .add('my:company:users', '/my/company/users', 'Company/CompanyUsersPage/CompanyUsersPage')
  .add('auth:login', '/auth', 'AuthPage')
  .add('auth:password-recovery', '/auth/password-recovery', 'PasswordRecoveryPage')
  .add('auth:register:company', '/register/company', 'Register/RegisterCompanyPage')
  .add('auth:register:candidate', '/register/candidate', 'Register/RegisterCandidatePage')
  .add('auth:register:common', '/register', 'Register/RegisterPage')
  .add('auth:password-setup', '/set-password/:passwordHash', 'Auth/SetupPasswordPage')
  .add('auth:verify-email', '/verify-email/', 'Auth/VerifyEmailPage')
  .add('auth:login:token', '/auth/token/:authToken', 'AuthorizeByTokenPage')
  .add('auth:logout', '/auth/logout', 'LogoutPage')
  .add('profile:edit', '/my/profile/edit', 'ProfileEditPage')
  .add('profile:notifications', '/my/profile/notifications', 'ProfileNotificationsPage/ProfileNotificationsPage')
  .add('profile:search-settings', '/my/profile/search-settings', 'Candidate/CandidateSearchSettingsPage')
  .add('profile:settings', '/my/profile/settings', 'ProfileSettingsPage')
  .add('vacancy:public', '/vacancy/:vacancyId', 'PublicVacancyPage')
  .add('candidate:vacancies', '/jobs', 'CandidateVacanciesPage')
  .add('candidate:cv:edit', '/my/cv/edit/:step?', 'Candidate/Cv/CandidateEditCvPage/CandidateEditCvPage')
  .add('candidate:cv:improve', '/my/cv/improve/:step?', 'Candidate/Cv/CandidateImproveCVPage/CandidateImproveCVPage')
  .add('candidate:invite', '/invite', 'Candidate/CandidateInvitePage/CandidateInvitePage')
  .add('candidate:invite-sber:new', '/invite-sber', 'Candidate/CandidateInvitePage/CandidateInviteSberPage')
  .add('candidate:invite-megafon:new', '/invite-megafon', 'Candidate/CandidateInvitePage/CandidateInviteMegafonPage')
  .add('candidate:personaloffer', '/personal-offer', 'v2/CandidatePersonalOfferPage')
  .add('candidate:search', '/search', 'v2/CandidateSuperSearchPage')
  .add('candidate:report', '/report', 'v2/CandidateReportPage')
  .add('auth:privacypolicy', '/privacy-policy', 'PrivacyPolicyPage')
  .add('auth:personaldataprocessingconsent', '/personal-data-processing-consent', 'PersonalDataProcessingConsentPage')
  .add('auth:termsofuse', '/terms-of-use', 'TermsOfUse/TermsOfUsePage')
  // .add('auth:publicoffer', '/public-offer', 'PublicOfferPage')
  .add('common:email-action', '/email-action', 'Common/EmailActionPage/EmailActionPage')
  .add('company-profile:company', '/company-profile/:companyId', 'Company/CompanyProfilePage')
  .add('company:pools:list', '/pools', 'v2/CompanyPoolsPage')
  .add('company:pool', '/pools/:poolId', 'v2/CompanyPoolPage')
  .add('company:accept-offer', '/accept-offer', 'v2/CompanyAcceptOfferPage');
