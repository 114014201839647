import { ServerResponse } from 'http';
import { destroyCookie, setCookie } from 'nookies';
import round from 'lodash/round';
import addYears from 'date-fns/addYears';
import parse from 'date-fns/parse';
import { IntlShape } from 'react-intl';
import { NextPageContext } from 'next';
import differenceInMonths from 'date-fns/differenceInMonths';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Router } from '../../server/routes';
import { RedirectError, ServerError } from '../server-error';

export const pageTitle = (title: string): string => `${title} - Geecko Marketplace`;

/**
 * Возвращает строку с прописным первым символом
 * @param input - Входная строка
 *
 * @returns Итоговая строка
 */
export const ucFirst = (input?: string): string =>
  input && input.length ? input.charAt(0).toUpperCase() + input.slice(1) : '';

export const copyToClipboard = (text: string, callback?: () => void) => {
  const dummy = document.createElement('input');
  dummy.style.opacity = '0';
  dummy.style.position = 'fixed';
  document.body.appendChild(dummy);
  if (dummy) {
    dummy.value = text;
    dummy.focus();
    dummy.select();
    document.execCommand('copy');
  }
  document.body.removeChild(dummy);
  if (callback) callback();
};

export const isServer = typeof window === 'undefined';

/**
 * Не использовать этот метод для редиректа между страницами, кроме серверных методов
 * Используйте redirect из utils/redirect
 */
export const nextRedirect = (url: string, res?: ServerResponse, code = 302) => {
  if (res) {
    res.writeHead(code, {
      Location: url,
    });
    res.end();
  } else {
    Router.push(url);
  }
};

export const shallowReplaceRoute = (url: string) => {
  Router.replaceRoute(url, {}, { shallow: true });
};

// Бросит исключение специального типа, который отобразит страницу 404 с указанным текстом и корректным http кодом
export const showNotFoundError = (title?: string) => {
  throw new ServerError(title || '404 - Страница не найдена', 404);
};

// Бросит исключние специального типа, который выполнит принудительный редирект на указанный url на сервере
export const serverRedirect = (redirectUrl: string, statusCode = 302) => {
  throw new RedirectError('RedirectError', redirectUrl, statusCode);
};

export const setHttpCookie = (name: string, value: string, context?: NextPageContext) => {
  setCookie(context, name, value, {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN || undefined,
    // secure: true,
    httpOnly: typeof context !== 'undefined',
    expires: addYears(new Date(), 2),
  });
};

export const deleteCookie = (name: string, context?: NextPageContext, withDomain = true) => {
  destroyCookie(context, name, {
    path: '/',
    domain: withDomain && process.env.REACT_APP_COOKIE_DOMAIN ? process.env.REACT_APP_COOKIE_DOMAIN : undefined,
    httpOnly: typeof context !== 'undefined',
  });
};

export const getSalaryNumber = (str: string | number) => {
  if (typeof str === 'number' || typeof str === 'undefined') return str;
  const input = str.replace(/\D/g, '');
  if (!input) return 0;
  return parseInt(input, 10);
};

export const formatOnlyNumbers = (value: number | undefined) => {
  if (typeof value !== 'number') {
    return '';
  }
  const input = value.toFixed().replace(/\D/g, '');
  if (!input) return '';
  const n = String(parseInt(input, 10));
  const p = n.indexOf('.');
  return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => (p < 0 || i < p ? `${m} ` : m));
};

export const monthsToYearsString = (months?: any): string => {
  if (!months || (typeof months !== 'string' && typeof months !== 'number')) {
    return '';
  }

  const monthsInteger = typeof months === 'number' ? months : parseInt(months, 10);
  if (!monthsInteger || isNaN(monthsInteger) || monthsInteger < 0) {
    return '';
  }

  const years = monthsInteger / 12;

  return (round(years * 2) / 2).toFixed();
};

export const yearsStringToMonths = (yearsString: string | undefined): number | null => {
  if (!yearsString) {
    return null;
  }

  const processedYearsString = yearsString.replace(',', '.').trim();
  const yearsFloat = parseFloat(processedYearsString);
  if (!yearsFloat || isNaN(yearsFloat) || yearsFloat < 0) {
    return 0;
  }

  return round(yearsFloat * 12);
};

// TODO: Удалить и использовать функцию из date-fns
export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const dateDiffInDays = (a: Date, b: Date) => {
  const msPerDay = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};

export const dateIsExpired = (date: Date | string, offset?: number) => {
  const compareDate = new Date(date);
  if (offset) compareDate.setDate(compareDate.getDate() + offset);
  const now = new Date().getTime();
  const distance = compareDate.getTime() - now;
  if (distance < 0) {
    return true;
  }
  return false;
};

export const pad = (num: number, size: number) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

export const logDebug = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
};

export const dateIntervalFormat = (intl: IntlShape, dateFrom?: Date, dateTo?: Date): string | undefined => {
  if (!dateFrom) {
    return undefined;
  }
  const dateRight = dateTo || new Date();
  const diff = differenceInMonths(dateRight, dateFrom);
  const years = Math.floor(diff / 12);
  const months = diff % 12;
  const parts: string[] = [];
  if (years) {
    parts.push(intl.formatMessage({ id: 'app.plural.years' }, { count: years }));
  }
  if (months) {
    parts.push(intl.formatMessage({ id: 'app.plural.months' }, { count: months }));
  }

  return parts.join(' ');
};

export const formatFinalFormDate = (
  intl: IntlShape,
  month?: string | number,
  year?: string | number,
): string | undefined => {
  const date = getDateFromFinalFormMonthAndYear(month, year);
  return intl.formatDate(date, { year: 'numeric', month: 'long' }).replace(' г.', '');
};

export const getDateFromFinalFormMonthAndYear = (month?: string | number, year?: string | number): Date | undefined => {
  if (!month || !year) {
    return undefined;
  }

  return parse(`${year}-${month}-1`, 'yyyy-M-d', 0);
};

export const formatFinalFormDateRange = (
  intl: IntlShape,
  startMonth?: string,
  startYear?: string,
  finishMonth?: string,
  finishYear?: string,
  isContinuous?: boolean,
): string | undefined => {
  const startDate = getDateFromFinalFormMonthAndYear(startMonth, startYear);
  const endDate = isContinuous ? new Date() : getDateFromFinalFormMonthAndYear(finishMonth, finishYear);

  return dateIntervalFormat(intl, startDate, endDate);
};
