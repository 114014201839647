export class ServerError extends Error {
  public statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

// Бросаем эту ошибку, если нам нужен редирект на стороне сервера
export class RedirectError extends Error {
  public statusCode: number;

  public redirectUrl: string;

  constructor(message: string, redirectUrl: string, statusCode = 302) {
    super(message);
    this.redirectUrl = redirectUrl;
    this.statusCode = statusCode;
  }
}
